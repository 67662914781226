
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import ProdutoModal from '@/views/Cadastros/Produtos/ProdutoModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';

export default defineComponent({
  name: 'Produto',
  components: {
    TelaPadraoCrud,
    ProdutoModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoProduto = new ServicoProduto();

    const refCrudProduto = ref<InstanceType<typeof TelaPadraoCrud>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = true;
    crudBase.estrutura.apresentarAcaoDuplicarGrade = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do produto:';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Identificador', dataIndex: 'codigo', key: 'IdentificadorProduto', position: 0, visible: false, ordering: false,
      },
      {
        title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInterno', position: 0, visible: true, ordering: true, width: 200,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoProduto', position: 1, visible: true, ordering: true, fixed: 'left', ellipsis: true, width: 400, align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Unidade', dataIndex: 'unidade', key: 'UnidadeProduto', position: 2, visible: true, ordering: false, align: 'center', width: 70,
      },
      {
        title: 'Marca', dataIndex: 'marca', key: 'marcaProduto', position: 3, visible: true, ordering: true, align: 'left', ellipsis: true, width: 150,
      },
      {
        title: 'Possui Variação', dataIndex: 'possuiVariacao', key: 'possuiVariacao', position: 4, visible: true, ordering: false, align: 'center', width: 130,
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'AtivoProduto', position: 5, visible: true, align: 'left', customRenderRow: ECustomRenderRow.TagAtivo, width: 80,
      },
      {
        title: 'NCM', dataIndex: 'ncm', key: 'ncmProduto', position: 6, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Ações', key: 'acoes', position: 7, visible: true, fixed: 'right', width: 120, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'IdentificadorProduto', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrudProduto.value !== undefined) {
        await refCrudProduto.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }
    return {
      crudBase,
      servicoProduto,
      refCrudProduto,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
    };
  },
});
